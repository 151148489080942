import { getAuthorizedRoutes } from './Nav';
import { Disclosure } from 'components/Elements/';
import MenuItem from './MenuItem';

const SideNavigation = ({ permissions = [] }) => {
  return (
    <div className="space-y-2 ">
      {getAuthorizedRoutes(permissions).map((item) => {
        if (item.dropdown)
          return (
            <Disclosure key={item.dropdown} title={item.dropdown} icon={item.icon}>
              {item.subroutes.map((route, i) => (
                <div className="px-2 " key={i}>
                  <MenuItem key={route.name} to={route.to}>
                    {route.name}
                  </MenuItem>
                </div>
              ))}
            </Disclosure>
          );

        if (!item.dropdown && !item.Component)
          return (
            <MenuItem key={item.name} to={item.to} Icon={item.icon}>
              {item.name}
            </MenuItem>
          );
        if (item.Component) return <item.Component />;
      })}
    </div>
  );
};

export default SideNavigation;
