const storagePrefix = 'botonverde_react_';

export const storage = {
  getToken: () => JSON.parse(localStorage.getItem(`${storagePrefix}token`)),
  setToken: (user) => {
    localStorage.setItem(`${storagePrefix}token`, JSON.stringify(user));
  },
  clearToken: () => {
    localStorage.removeItem(`${storagePrefix}token`);
  },
};
