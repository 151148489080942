import { ResetPass } from './ResetPassword';

export const columns = [
  {
    Header: 'Nombre',
    accessor: 'profile.nombre',
  },
  {
    Header: 'Email',
    accessor: 'email',
  },
  {
    Header: 'Rol',
    Cell: ({ row: { original } }) => {
      return <div> {original.role?.nombre || ''} </div>;
    },
  },
  {
    Header: 'Clave',
    Cell: ({ row: { original } }) => <ResetPass userId={original.id} />,
  },
];

export const form = [
  {
    type: 'text',
    label: 'Nombre',
    name: 'nombre',
    placeholder: 'Nombre',
    required: true,
  },
  {
    type: 'text',
    label: 'Email',
    name: 'email',
    placeholder: 'email',
    required: true,
  },
  {
    type: 'select',
    label: 'Rol',
    name: 'role',
    placeholder: 'rol',
    queryName: 'roles',
    required: true,
  },
  {
    type: 'select',
    label: 'Institución',
    name: 'institucion',
    placeholder: 'Institución',
    queryName: 'instituciones?activo=true',
    required: true,
  },
  {
    type: 'text',
    label: 'Teléfono',
    name: 'telefono',
    placeholder: 'telefono',
  },
  {
    type: 'text',
    label: 'Móvil',
    name: 'movil',
    placeholder: 'movil',
  },
  {
    type: 'password',
    label: 'Contraseńa',
    name: 'password',
    placeholder: 'contraseńa',
    required: true,
  },
  {
    type: 'password',
    label: 'Confirmar contraseńa',
    name: 'password_confirmation',
    placeholder: 'confirmar contraseńa',
    required: true,
  },
];

export const fieldsHidden = {
  password_confirmation: 'password_confirmation',
  password: 'password',
};
