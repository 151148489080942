import 'react-quill/dist/quill.snow.css';

import { useState } from 'react';
import ReactQuill from 'react-quill';

export const RichText = ({ onChange, value, placeholder = 'leyenda' }) => {
  const [state, setState] = useState({
    theme: 'snow',
  });

  function handleThemeChange(newTheme) {
    if (newTheme === 'core') newTheme = null;
    setState({ theme: newTheme });
  }

  return (
    <div>
      <ReactQuill
        theme={state.theme}
        onChange={onChange}
        value={value}
        modules={modules}
        formats={formats}
        bounds={'.app'}
        placeholder={placeholder}
      />
      <div className="themeSwitcher">
        <label>Temas </label>
        <select onChange={(e) => handleThemeChange(e.target.value)}>
          <option value="snow">Snow</option>
          <option value="bubble">Bubble</option>
          <option value="core">Core</option>
        </select>
      </div>
    </div>
  );
};

const modules = {
  toolbar: [
    [{ header: '1' }, { header: '2' }, { font: [] }],
    [{ size: [] }],
    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
    ['link', 'image', 'video'],
    ['clean'],
  ],
  clipboard: {
    matchVisual: false,
  },
};

const formats = [
  'header',
  'font',
  'size',
  'bold',
  'italic',
  'underline',
  'strike',
  'blockquote',
  'list',
  'bullet',
  'indent',
  'link',
  'image',
  'video',
];
