import { useState, useEffect } from 'react';
import { useNotificationStore } from 'stores/notifications';
import { axios } from 'lib/axios';
import { passwordBuilder } from 'utils/password-builder';

import { RiLockPasswordLine } from 'react-icons/ri';
import { Button } from 'components/Elements';
import { ConfirmationDialog } from 'components/Elements/ConfirmationDialog';

export const resetPass = async (id, objPass) => {
  const { data } = await axios.post(`/usuarios/${id}/resetpassword`, objPass);
  return data;
};

export const ResetPass = ({ userId }) => {
  const { addNotification } = useNotificationStore();
  const [done, setDone] = useState(false);
  const [password, setPass] = useState(null);

  const onSubmit = async () => {
    try {
      await resetPass(userId, {
        password: password,
        password_confirmation: password,
      });
      addNotification({
        type: 'success',
        title: 'Reseteada exitosamente!',
      });
      setDone(true);
    } catch (error) {}
  };

  return (
    <ConfirmationDialog
      title="Resetear contraseńa"
      isDone={done}
      onOpen={() => setPass(passwordBuilder())}
      body={
        <p>
          La contraseńa por defecto es <b> {password} </b>
        </p>
      }
      triggerButton={
        <Button variant="inverse" startIcon={<RiLockPasswordLine className="h-4 w-4" />} size="sm">
          Resetear contraseńa
        </Button>
      }
      confirmButton={
        <span onClick={onSubmit}>
          <Button
            variant="dangerInversed"
            startIcon={<RiLockPasswordLine className="h-4 w-4" />}
            size="sm"
          >
            Resetear contraseńa
          </Button>
        </span>
      }
    />
  );
};
