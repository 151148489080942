/* eslint-disable no-unused-vars */
import React from 'react';
import Sidebar from './Sidebar';
import MobileSidebar from './MobileSidebar';
import UserNavigation from './UserOption';

import { FiMenu } from 'react-icons/fi';
import { useAuth } from 'lib/auth';
import { Footer } from './Footer';

export const MainLayout = ({ children }) => {
  const [sidebarOpen, setSidebarOpen] = React.useState(false);
  const { logout, user } = useAuth();

  return (
    <div className="h-screen flex overflow-hidden bg-gray-100">
      <MobileSidebar user={user} sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
      <Sidebar user={user} />
      <div className="flex flex-col w-0 flex-1 overflow-hidden">
        <div className="relative z-10 flex-shrink-0 flex h-16 bg-white shadow">
          <button
            type="button"
            className="px-4 border-r border-gray-200 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500 md:hidden"
            onClick={() => setSidebarOpen(true)}
          >
            <span className="sr-only">Open sidebar</span>
            <FiMenu className="h-6 w-6" aria-hidden="true" />
          </button>
          <div className="flex-1 px-4 bg-blue-ogtic flex justify-between">
            {user?.user?.institucion?.nombre && (
              <p className="text-white p-4 sm:text-base text-xs "> {user?.user?.institucion?.nombre} </p>
            )}
            <div className="ml-4 flex items-center md:ml-6">
              <UserNavigation logout={logout} user={user} />
            </div>
          </div>
        </div>
        <main className="overflow-y-auto focus:outline-none h-screen flex flex-col justify-between">
          {children}
          <Footer />
        </main>
      </div>
    </div>
  );
};
