import { useNotificationStore } from 'stores/notifications';
import { useState } from 'react';
import { apiBuilder, apiBuilderPaths } from 'utils/api-builder';

export function crudActions(queryName, paths) {
  const { useCreate, useDelete, useUpdate, useGetAll } = paths
    ? apiBuilderPaths(paths)
    : apiBuilder(queryName);

  const [filter, setFilter] = useState('');
  const { data, isLoading } = useGetAll();
  const { addNotification } = useNotificationStore();

  const createMutation = useCreate();
  const deleteMutation = useDelete();
  const updateMutation = useUpdate();

  const onCreate = async (data) => {
    await createMutation.mutateAsync(data).then((res) => {
      if (res)
        return addNotification({
          type: 'success',
          title: 'creado correctamente!',
        });
    });
  };

  const onUpdate = async (data, id) => {
    await updateMutation
      .mutateAsync({
        data,
        id,
      })
      .then((res) => {
        if (res)
          return addNotification({
            type: 'success',
            title: ' actualizado correctamente!',
          });
      });
  };

  const onDelete = async (id) => {
    await deleteMutation.mutateAsync(id).then((res) => {
      if (res) {
        return addNotification({
          type: 'success',
          title: `Eliminado correctamente!`,
        });
      }
    });
  };

  return [
    { data, isLoading, filter },
    { setFilter, onUpdate, onDelete, onCreate },
  ];
}
