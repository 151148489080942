import { useDisclosure } from 'hooks/useDiscloure';
import { Dialog } from '../../Elements/Dialog';
import { Button } from '../../Elements/Button';
import { FiAlertCircle } from 'react-icons/fi';

export const DetailBuilder = ({ labels, data, children }) => {
  const { isOpen, open, close } = useDisclosure();
  return (
    <>
      <div onClick={open}>{children}</div>
      <Dialog isOpen={isOpen} open={open} onClose={close}>
        <div className=" border-2 inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
          <div>
            <div className="flex space-x-4 mb-4 border-b pb-2">
              <FiAlertCircle className="h-10 w-10 text-blue-600" />
              <p className="self-center text-lg font-bold">Detalle</p>
            </div>
            <div className="grid grid-cols-1 space-y-2 text-sm">
              {labels.map((item) => {
                if (['password', 'password_confirmation'].includes(item.name)) return null;
                return (
                  <div key={item}>
                    <p>
                      <b>{item.label}: </b>{' '}
                      {item.type === 'select'
                        ? data[item.name]?.nombre || data[item.name]
                        : data[item.name]}{' '}
                    </p>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="flex justify-end mt-10 border-t">
            <div onClick={close} className="self-center mt-4">
              <Button variant="inverse">Cerrar</Button>
            </div>
          </div>
        </div>
      </Dialog>
    </>
  );
};
