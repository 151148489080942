/* eslint-disable prefer-destructuring */
/* eslint-disable no-param-reassign */
import Axios from 'axios';

import { API_URL } from 'config';
import { useNotificationStore } from 'stores/notifications';
import { storage } from 'utils';

function authRequestInterceptor(config) {
  const newConfig = config;
  const token = storage.getToken();

  if (token) {
    newConfig.headers.authorization = `Bearer ${token?.token}`;
  }

  newConfig.headers.Accept = 'application/json';
  return newConfig;
}

export const axios = Axios.create({
  baseURL: API_URL,
  // timeout: 60000,
});

axios.interceptors.request.use(authRequestInterceptor);
axios.interceptors.response.use(
  (response) => response.data,
  (error) => {
    let message;

    if (error.response?.data?.errors) {
      const errorsMessage = Object.entries(error.response?.data?.errors);
      message = errorsMessage[0][1][0];
    } else if (error.response?.data?.message) {
      message = error.response?.data?.message;
    } else {
      message = error.message;
    }

    // esto deberia mandar la ubicacion actual al login y luego que se loguee otra vez se vuelva a la pagina anterior
    if (message === 'Unauthenticated.') {
      storage.clearToken();
      window.location.reload(false);
    }

    useNotificationStore.getState().addNotification({
      type: 'error',
      title: 'Error',
      message,
    });

    Promise.reject(error);
  }
);
