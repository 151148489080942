import SideNavigation from './SideNavigation';
import Logo from './Logo';

const Sidebar = ({ user: { user } }) => {
  return (
    <div className="hidden md:flex md:flex-shrink-0">
      <div className="flex flex-col w-64">
        <div className="flex flex-col h-0 flex-1">
          <div className="flex p-4 items-center  flex-shrink-0  bg-white">
            <Logo />
          </div>
          <div className="flex-1 flex flex-col overflow-y-auto">
            <nav className="flex-1 pl-2 py-4 bg-white space-y-1 overflow-x-auto">
              <SideNavigation permissions={user?.modulos || []} />
            </nav>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
