import MenuItem from 'components/Layout/MenuItem';

import { axios } from 'lib/axios';
import { useQuery } from 'react-query';

import { AiOutlineMenuFold } from 'react-icons/ai';
import { Disclosure } from 'components/Elements';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router';

export const fn = async () => axios.get('/menus').then((data) => data?.data);

export default function MenuBuilder() {
  const nav = useNavigate();

  const { data } = useQuery('menus', fn);
  const { child } = useParams();

  return (
    <div to="menus">
      <Disclosure onClick={() => nav('menus')} title="Archivos de menú" icon={AiOutlineMenuFold}>
        <div className="ml-2 space-y-2">
          {(data || []).map((it, index) => {
            if (it.tipo === 'collapse' && !it.parent_id) {
              return (
                <Disclosure
                  onClick={() => nav(`/menus/${it.nombre}`)}
                  title={`- ${it.nombre}`}
                  key={it.slug}
                >
                  <div className="ml-4 ">
                    {it.submenus
                      .sort((a, b) => (a.orden > b.orden ? 1 : b.orden < a.orden ? -1 : 0))
                      .map((menu, i) => {
                        return (
                          <MenuItem
                            isExact={(child || '').replace('}', '').toString() === menu.slug}
                            to={`/menus/${it.nombre}/${menu.slug}}`}
                            key={i}
                          >
                            <p className="cursor-pointer">* {menu.nombre}</p>
                          </MenuItem>
                        );
                      })}
                  </div>
                </Disclosure>
              );
            }

            if (!(it.tipo === 'collapse') && !it.parent_id) {
              return (
                <MenuItem
                  isExact={(child || '').replace('}', '').toString() === it.slug}
                  to={`/menus/${it.nombre}/${it.slug}}`}
                  key={index}
                >
                  <p className="cursor-pointer">* {it.nombre}</p>
                </MenuItem>
              );
            }
          })}
        </div>
      </Disclosure>
    </div>
  );
}
