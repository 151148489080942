import { Disclosure as HUIDisclosure, Transition } from '@headlessui/react';
import { createElement } from 'react';

export const Disclosure = ({ title, icon, children, onClick }) => {
  return (
    <HUIDisclosure>
      {({ open }) => (
        <>
          <span onClick={onClick && onClick}>
            <HUIDisclosure.Button
              className={`flex items-center w-full px-2 py-2 text-sm font-medium text-left text-truncate ${
                open && 'bg-gray-100 text-white'
              } text-gray-800 rounded-lg hover:bg-gray-100  focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75`}
            >
              {icon &&
                createElement(icon, {
                  className: `${
                    open ? '' : 'transform rotate-180  text-gray-800   '
                  } h-6 w-6  transition-all duration-200 mr-4 hover:text-white `,
                })}
              <span>{title}</span>
            </HUIDisclosure.Button>
          </span>

          <Transition
            enter="transition duration-100 ease-out"
            enterFrom="transform scale-95 opacity-0"
            enterTo="transform scale-100 opacity-100"
            leave="transition duration-100 ease-in"
            leaveFrom="transform scale-100 opacity-100"
            leaveTo="transform scale-95 opacity-0"
          >
            <HUIDisclosure.Panel>
              {' '}
              <div className="bg-white rounded-lg">{children}</div>{' '}
            </HUIDisclosure.Panel>
          </Transition>
        </>
      )}
    </HUIDisclosure>
  );
};
