import TRANSPARENCIA from 'assets/img/logo.png';
import { Link } from 'react-router-dom';

const Logo = () => (
  <Link className="flex justify-center mr-3 w-full text-white" to=".">
    <img className="" src={TRANSPARENCIA} alt="Workflow" />
  </Link>
);

export default Logo;
