import { lazy } from 'react';
import { Outlet } from 'react-router-dom';

import { Spinner } from 'components/Elements';
import { MainLayout } from 'components/Layout';
import { lazyImport } from 'utils/lazyImport';
import { Suspense } from 'react';
import { pagesBuilding } from '../cruds';

import AuthCitizen from '../providers/auth-citizen';
import GetPermissions from '../providers/get-permissions';

const File = lazy(() => import('../pages/files'));
const AdmMenu = lazy(() => import('pages/menus/menu'));
const Dashboard = lazy(() => import('pages/dashboard'));

const { MyInstitution } = lazyImport(() => import('pages/my-institution'), 'MyInstitution');
const { MeRoutes } = lazyImport(() => import('pages/me'), 'MeRoutes');
const { NotFound } = lazyImport(() => import('pages/404'), 'NotFound');
const { Institution } = lazyImport(() => import('pages/institutions'), 'Institution');
const { Roles } = lazyImport(() => import('pages/roles'), 'Roles');
const { BitacoraApp } = lazyImport(() => import('pages/bitacora-app'), 'BitacoraApp');
const { Menus } = lazyImport(() => import('pages/menus'), 'Menus');

export const SpinnerFallback = () => (
  <div className="fadeIn h-screen w-auto flex items-center justify-center transition-all duration-200">
    <Spinner size="xl" />
  </div>
);

const App = () => (
  <MainLayout>
    <AuthCitizen>
      <GetPermissions>
        <Suspense fallback={<SpinnerFallback />}>
          <div className="fadeIn">
            <Outlet />
          </div>
        </Suspense>
      </GetPermissions>
    </AuthCitizen>
  </MainLayout>
);

const pages = pagesBuilding();

export const protectedRoutes = [
  {
    path: '/',
    element: <App />,
    children: [
      ...pages,
      {
        path: '/menus/*',
        element: <Menus />,
        children: [
          {
            path: ':parent',
            element: <File />,
          },
          {
            path: ':parent/:child',
            element: <File />,
          },
        ],
      },
      {
        path: '/dashboard', 
        element: <Dashboard/>
      },
      {
        path: '/adm-menu',
        element: <AdmMenu />,
      },
      {
        path: '/me/*',
        element: <MeRoutes />,
      },
      {
        path: '/my-institution',
        element: <MyInstitution />,
      },
      {
        path: '*',
        element: <NotFound />,
      },
      {
        path: '/institutions',
        element: <Institution />,
      },
      {
        path: '/role',
        element: <Roles />,
      },
      {
        path: '/bitacoras-app',
        element: <BitacoraApp />,
      },
    ],
  },
];
