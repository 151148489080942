import { FiLifeBuoy, FiCommand } from 'react-icons/fi';
import { FaHistory } from 'react-icons/fa';
import {GoDashboard} from 'react-icons/go';
import MenuBuilder from 'components/Builders/MenuBuilder';

export const navigation = [
  { name: 'Dashboard', to: './dashboard', permission: 'dashboard', icon: GoDashboard },
  { name: 'Menú', to: './adm-menu', permission: 'menus', icon: FiLifeBuoy },
  {
    permission: 'menu_institucion',
    icon: FiLifeBuoy,
    Component: MenuBuilder,
  },
  { name: 'Instituciones', to: './institutions', permission: 'instituciones', icon: FiLifeBuoy },
  {
    dropdown: 'Usuarios',
    icon: FiCommand,
    subroutes: [
      {
        name: 'Usuarios',
        to: './users',
        permission: 'usuarios',
      },
      { name: 'Roles', to: './role', permission: 'roles' },
    ],
  },
  {
    name: 'Bitácora',
    icon: FaHistory,
    permission: 'bitacoras',
    to: './bitacoras-app',
  },
];

export const getAuthorizedRoutes = (modules = []) => {
  const reducedPermits = modules.reduce(
    (acc, curr) => ({
      ...acc,
      [curr.modulo]: curr,
    }),
    {}
  );

  return navigation.filter((item) => {
    if (item.permission === 'dashboard') return true;
    if (reducedPermits[item.permission]) return true;
    if (item.subroutes && !item.permission) {
      const authorizedSub = item.subroutes.filter((sub) => reducedPermits[sub.permission]);
      item.subroutes = authorizedSub;

      if (authorizedSub.length) return true;
      if (!authorizedSub.length) return false;
    }
    return false;
  });
};
