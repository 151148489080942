import {
  AiOutlineFacebook,
  AiOutlineInstagram,
  AiOutlineTwitter,
  AiOutlineYoutube,
} from 'react-icons/ai';

import OGTIC from 'assets/img/LOGO_OGTIC.svg';

export const Footer = () => (
  <div className="border shadow">
    <footer>
      <div className="bg-white ">
        <div className="container mx-auto py-4 px-5 flex flex-wrap items-center flex-col xs:flex-row">
          <div className="mb-8 md:mb-0 text-center grid justify-center gap-4 lg:flex lg:items-center">
            ©2021 Todos los derechos reservados. <br className="md:hidden" />
            Desarrollado por
            <div className="flex gap-4 justify-center items-center">
              <img className="w-16" src={OGTIC} alt="" />
            </div>
          </div>
          <span className="inline-flex xs:ml-auto xs:mt-0 mt-2 justify-center items-center xs:justify-start">
            <p className="azul text-xs font-semibold">SÍGUENOS EN</p>
            <a
              className="ml-3 flex-shrink-0 cursor-pointer"
              href="https://www.facebook.com/Ogticrd"
              target="_blank"
              rel="noreferrer"
            >
              <AiOutlineFacebook className="text-blue-900 w-6 h-6 hover:text-blue-900 transform hover:scale-110 transition-all duration-200" />
            </a>
            <a
              className="ml-3 flex-shrink-0 cursor-pointer"
              href="https://www.youtube.com/user/OPTICRD"
              target="_blank"
              rel="noreferrer"
            >
              <AiOutlineYoutube className="text-blue-900 w-7 h-7 hover:text-red-600 transform hover:scale-110 transition-all duration-200" />
            </a>
            <a
              className="ml-3 flex-shrink-0 cursor-pointer"
              href="https://twitter.com/OGTICRDO"
              target="_blank"
              rel="noreferrer"
            >
              <AiOutlineTwitter className="text-blue-900 w-7 h-7 hover:text-blue-500 transform hover:scale-110 transition-all duration-200" />
            </a>
            <a
              className="ml-3 flex-shrink-0 cursor-pointer"
              href="https://www.instagram.com/ogticrd/"
              target="_blank"
              rel="noreferrer"
            >
              <AiOutlineInstagram className="text-blue-900 w-7 h-7 hover:text-pink-600 transform hover:scale-110 transition-all duration-200" />
            </a>
          </span>
        </div>
      </div>
    </footer>
  </div>
);
