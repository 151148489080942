import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import clsx from 'clsx';

export default function MenuItem({ to, Icon, children, isExact = null }) {
  const nav = useNavigate();

  const location = useLocation();
  const curr = location.pathname.split('/')[1];
  const exact = curr.includes(to.split('/')[1]);
  const realExact = isExact !== null ? isExact : exact;

  useEffect(() => {
    if (location.pathname === '/') nav('dashboard');
  }, [location.pathname]);
  
  return (
    <>
      <span
        className={`${
          realExact ? 'bg-option-selected' : 'text-black  hover:bg-gray-100'
        }   group flex items-center pl-2 py-2 text-sm font-medium  transition duration-75 ease-in-out  rounded-tl-full rounded-bl-full`}
        onClick={() => nav(to)}
      >
        {Icon && (
          <Icon
            className={clsx(
              !exact && !isExact ? ' text-gray-800 ' : '',
              'mr-3 flex-shrink-0 h-5 w-5'
            )}
            aria-hidden="true"
          />
        )}
        {children}
      </span>
    </>
  );
}
