import { form, columns, fieldsHidden } from './fields';
import { schemaUpdate, schemaCreate } from './validators';

const title = 'Usuarios';

const maskDefaultValues = (defaultValues) => {
  const payload = { ...defaultValues, ...defaultValues.profile };
  payload['role'] = typeof payload.role === 'object' ? payload.role.id : payload.role;
  return payload;
};
const maskDataShow = (data) => {
  const payload = { ...data, ...data.profile };
  payload['password'] = '---';
  payload['password_confirmation'] = '---';
  return payload;
};

const maskSaveData = (data) => ({
  ...data,
  institucion: data?.institucion?.id || data?.institucion,
});

const values = {
  moduleName: title,
  apiUrl: 'usuarios',
  pathUrl: '/users',
  columns,
  schemaUpdate,
  schemaCreate,
  fieldsHidden,
  maskDefaultValues,
  maskDataShow: maskDataShow,
  maskSaveData,
  saveProps: {
    arrFields: form,
    title: title,
    formId: title,
  },
};

export default values;
