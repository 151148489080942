import { PageBuilding } from 'components/Builders/PageBuilding';
import { ContentLayout } from 'components/Layout/ContentLayout';

import users from './accessories/users';

const jsonPages = [users];

export const buildPages = (pages) => {
  return pages.map((page) => ({
    path: page.pathUrl,
    element: (
      <ContentLayout title={page.moduleName}>
        <div className="mt-4">
          <PageBuilding
            path={page.apiUrl}
            paths={page.apiUrls}
            keyDefaultValue={page.keyDefaultValue}
            fieldsHidden={page.fieldsHidden || null}
            columns={page.columns}
            schemaCreate={page.schemaCreate}
            schemaUpdate={page.schemaUpdate}
            saveProps={page.saveProps}
            dontCreate={page.dontCreate}
            dontUpdate={page.dontUpdate}
            dontDelete={page.dontDelete}
            maskDefaultValues={page.maskDefaultValues}
            maskSaveData={page.maskSaveData}
            maskDataShow={page.maskDataShow}
          />
        </div>
      </ContentLayout>
    ),
  }));
};

export const pagesBuilding = () => {
  return buildPages(jsonPages);
};
